//gatsby-browser.js
import React from 'react';
import { AppProvider } from '@src/context';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';

import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/700-italic.css';
import '@fontsource/poppins/800-italic.css';
import '@fontsource/poppins/900-italic.css';

import "./src/styles/cookies.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>{element}</AppProvider>
  );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
      event: 'gatsbyRouteChange',
      previousPage: prevLocation ? prevLocation.pathname + prevLocation.search : null,
      currentPage: location.pathname + location.search,
  });
};
