exports.components = {
  "component---src-pages-care-partners-js": () => import("./../../../src/pages/care-partners.js" /* webpackChunkName: "component---src-pages-care-partners-js" */),
  "component---src-pages-disclosures-js": () => import("./../../../src/pages/disclosures.js" /* webpackChunkName: "component---src-pages-disclosures-js" */),
  "component---src-pages-healthcare-professionals-js": () => import("./../../../src/pages/healthcare-professionals.js" /* webpackChunkName: "component---src-pages-healthcare-professionals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-not-found-js": () => import("./../../../src/pages/page-not-found.js" /* webpackChunkName: "component---src-pages-page-not-found-js" */),
  "component---src-pages-patients-js": () => import("./../../../src/pages/patients.js" /* webpackChunkName: "component---src-pages-patients-js" */),
  "component---src-pages-server-error-js": () => import("./../../../src/pages/server-error.js" /* webpackChunkName: "component---src-pages-server-error-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

