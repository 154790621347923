import React, { useState, createContext } from 'react';

const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [uuid, setUuid] = useState(null);
  const [utmValues, setUtmValues] = useState(null);

  const store = {
    uuid,
    setUuid,
    utmValues,
    setUtmValues,
  };
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
